import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
        if (data.company) {
            datas.company = data?.company
        }
    }
    return ApiService.get('/company/company_users/', datas)
}
export const createApiRecord = data => ApiService.post('/company/user/', data)
export const getApiRecord = id => ApiService.get(`/company/user/${id}/`)
export const updateApiRecord = (id, data) => ApiService.put(`/company/user/${id}/`, data)
export const deleteApiRecord = id => ApiService.delete(`/company/user/${id}/`)
