import React from 'react'
import { HiOutlineOfficeBuilding, HiOutlineUsers } from 'react-icons/hi'
import { BiBasket } from 'react-icons/bi'
import { GrAppsRounded } from 'react-icons/gr'

const menuItems = [
    { id: 0, label: 'Dashboard', module: 'dashboard', link: '/', icon: <GrAppsRounded size={20} /> },
    {
        id: 1,
        label: 'Company',
        module: 'company',
        link: '/company',
        icon: <HiOutlineOfficeBuilding size={20} />,
    },
    {
        id: 2,
        label: 'User',
        module: 'user',
        link: '/user',
        icon: <HiOutlineUsers size={20} />,
    },
    {
        id: 3,
        label: 'Manage Product',
        module: 'manage-product',
        icon: <BiBasket size={20} />,
        children: [
            { id: 31, label: 'Categories', module: 'categories', link: '/manage-product/categories' },
            { id: 32, label: 'Products', module: 'products', link: '/manage-product/products' },
            { id: 33, label: 'Unassigned Products', module: 'unassigned-products', link: '/manage-product/unassigned-products' },
        ],
    },
]

export default menuItems
