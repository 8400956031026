import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.is_active = data.selectedTab === 'active'
        }
        if (data?.checkBox?.length === 1) {
            datas.is_temporary = !data?.checkBox?.includes('register')
        }
        if (data.id) {
            datas.id = data.id
        }
    }
    return ApiService.get('/company/company/', datas)
}
export const createApiRecord = data => ApiService.post('/company/company/', data)
export const getApiRecord = id => ApiService.get(`/company/company/${id}/`)
export const updateApiRecord = (id, data) => ApiService.put(`/company/company/${id}/`, data)
export const deleteApiRecord = id => ApiService.delete(`/company/company/${id}/`)
