import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import Tooltip from 'components/Ui/Tooltip'
import { Modal, Table } from 'components/Ui'
import { Button, Select } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { assignProductApi, getInvoiceDocApi } from 'services/ProductService'
import { Success } from 'utils/alerts'
import { useNavigate } from 'react-router-dom'
import { getProductDataRaw } from '../../../store/data/dataSlice'
import { HiOutlinePencil } from 'react-icons/hi'

injectReducer('unassignedProduct', reducer)

const { REACT_APP_URL } = process.env

const validationSchema = Yup.object().shape({
    product: Yup.string().required('please select product'),
})

export default function Products() {
    const dispatch = useDispatch()
    const { productData } = useSelector(state => state.listData)

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getProductDataRaw()) // Dispatch the thunk to fetch product data
    }, [dispatch])

    const { data, tableData } = useSelector(state => state.unassignedProduct.data)
    const [open, setOpen] = useState(false)
    const [userProductId, setUserProductId] = useState(0)
    const [invoiceDoc, setInvoiceDoc] = useState([])

    const [userProductUrl, setUserProductUrl] = useState('')

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const handleAssignCompany = (id, url) => {
        getInvoiceDocApi({ user_product_id: id })
            .then(response => setInvoiceDoc(response.data))
            .catch(error => console.log('Error: ', error))
        setOpen(true)
        setUserProductId(id)

        setUserProductUrl(url)
    }

    const onAssign = (data, setSubmitting) => {
        setSubmitting(true)
        assignProductApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Assign Successfully!')
                setInvoiceDoc([])
                setOpen(false)
                fetchData()

                if (window.confirm('Do you want to add product details and complete registration?')) {
                    navigate(userProductUrl)
                }
            })
            .catch(error => {
                setSubmitting(false)
                console.log('Error: ', error)
            })
    }

    const columns = useMemo(
        () => [
            {
                field: 'user',
                header: 'Customer',
                className: 'min-w-[150px]',
                renderCell: row => `${row?.user?.first_name} ${row?.user?.last_name}`,
            },
            {
                field: 'product_label',
                header: 'product',
                className: 'min-w-[150px]',
                renderCell: row => row.product_label ?? row?.product?.name,
            },
            {
                field: 'company',
                header: 'company',
                className: 'min-w-[150px]',
                renderCell: row => row.company.name,
            },
            // {
            //     field: 'total_price',
            //     header: 'Total Price',
            //     className: `min-w-[150px]`,
            //     bg: 'total_price_confidence',
            // },
            {
                field: 'invoice_number',
                header: 'Invoice Number',
                className: 'min-w-[150px]',
                bg: 'invoice_confidence',
            },
            {
                field: 'product_serial_number',
                header: 'Serial Number',
                className: 'min-w-[150px]',
            },
            {
                field: 'image',
                header: 'Image',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <div className="flex items-center justify-center w-20 h-20 overflow-hidden">
                            <a href={row?.user_product_photos[0]?.image} target="_blank" rel="noreferrer">
                                <img
                                    src={row?.user_product_photos[0]?.image ?? row?.product?.image ?? '/images/ypp-logo.png'}
                                    alt="error"
                                />
                            </a>
                        </div>
                    )
                },
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Modify'}>
                                <Button
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        //handleAssignCompany(row?.id,`/manage-product/products/serials/${row?.product?.id}/${row?.id}/edit`)
                                        navigate(`/manage-product/products/${row?.product?.id}/edit`)
                                    }}
                                >
                                    <HiOutlinePencil size={20} />
                                </Button>
                            </Tooltip>

                            <Tooltip title={'Assign Product'}>
                                <Button
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        handleAssignCompany(
                                            row?.id,
                                            `/manage-product/products/serials/${row?.product?.id}/${row?.id}/edit`
                                        )
                                    }}
                                >
                                    <MdOutlineAssignmentTurnedIn size={24} />
                                </Button>
                            </Tooltip>

                            {/*<Tooltip title={'Edit'}>*/}
                            {/*    <Link*/}
                            {/*        className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta4"*/}
                            {/*        to={``}*/}
                            {/*    >*/}
                            {/*        <MdOutlineAssignmentTurnedIn size={28}*/}
                            {/*            // onClick={() => handleAssignCompany(row?.id)}*/}
                            {/*        />*/}
                            {/*    </Link>*/}
                            {/*</Tooltip>*/}
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Unassigned Products</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={false}
                    search_show={false}
                    datas={data}
                    columns={columns}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
            <Modal
                show={open}
                onClose={() => {
                    setOpen(false)
                    setInvoiceDoc([])
                }}
            >
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">Assign Company</h2>
                    {invoiceDoc[0]?.image ? (
                        <div className="flex justify-center w-full">
                            <div className="flex items-center justify-center w-40 h-40 mb-4 overflow-hidden">
                                <a href={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} target="_blank" rel="noreferrer">
                                    <img src={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} alt="error" />
                                </a>
                            </div>
                        </div>
                    ) : null}
                    <Formik
                        initialValues={{
                            product: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            onAssign({ ...values, user_product: userProductId }, setSubmitting)
                        }}
                    >
                        {({ values, touched, errors, isSubmitting }) => (
                            <Form className="flex flex-col gap-3">
                                {/*<Field name="company">*/}
                                {/*    {({ field, form }) => (*/}
                                {/*        <Select*/}
                                {/*            options={companyData?.filter(item => !item?.is_temporary && item?.registered_with_us)}*/}
                                {/*            label="Company"*/}
                                {/*            size="sm"*/}
                                {/*            placeholder="Select"*/}
                                {/*            className="md:col-span-2"*/}
                                {/*            name="company"*/}
                                {/*            error={errors.company && touched.company}*/}
                                {/*            message={errors.company}*/}
                                {/*            value={values?.company}*/}
                                {/*            onChange={e => form.setFieldValue(field.name, e.target.value)}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*</Field>*/}

                                <Field name="product">
                                    {({ field, form }) => (
                                        <Select
                                            options={productData?.filter(item => true)}
                                            label="Product"
                                            size="sm"
                                            placeholder="Select"
                                            className="md:col-span-2"
                                            name="product"
                                            error={errors.product && touched.product}
                                            message={errors.product}
                                            value={values?.product}
                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                        />
                                    )}
                                </Field>

                                <Button
                                    className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? 'Assigning...' : 'Assign'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}
