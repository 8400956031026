// import { moduleList } from 'utils/options'

export function companyName(company) {
    return (
        <div className="flex items-center gap-1">
            <span className="rounded-full w-11 h-11 bg-primary dark:bg-secondary">
                <div className="flex items-center justify-center text-xl font-bold text-white w-11 h-11">
                    {company.image ? (
                        <img className="rounded-full w-11 h-11" src={company.image} alt="logo" />
                    ) : company?.name ? (
                        `${company?.name[0]?.toUpperCase()}${company?.name[1]?.toUpperCase()}`
                    ) : (
                        'QU'
                    )}
                </div>
            </span>
            {company?.name}
        </div>
    )
}

export function serviceName(service) {
    return (
        <div className="flex items-center gap-1">
            <span className="rounded-full w-11 h-11">
                <img className="rounded-full w-11 h-11" src={service?.images[service?.images?.length - 1]?.image} alt="logo" />
            </span>
            {service?.service}
        </div>
    )
}

export function featureName(feature) {
    return (
        <div className="flex items-center gap-1">
            <span className="rounded-full w-11 h-11">
                <img className="rounded-full w-11 h-11" src={feature?.images[feature?.images?.length - 1]?.image} alt="logo" />
            </span>
            {['Machine', 'Grinder'].includes(feature.feature_type)
                ? `${feature?.feature}, Brand-${feature?.brand?.name}, Model-${feature?.machine?.name}`
                : feature?.feature}
        </div>
    )
}

export function status(status, check = false) {
    let className = ''
    let name = ''
    switch (status) {
        case true:
            className = 'text-success bg-success'
            name = 'Active'
            break
        default:
            className = 'text-danger bg-danger'
            name = 'Inactive'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function numberFormat(
    value,
    currency,
    digits = 2,
    style = 'currency' // decimal, currency, percent, unit
) {
    value = style === 'percent' ? value / 100 : value
    return new Intl.NumberFormat('en-US', {
        style: style,
        currency: currency ?? 'USD',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    }).format(value)
}

export function taxType(tax_type, check = false) {
    var className = ''
    var name = ''
    switch (tax_type) {
        case 0:
            className = 'text-success bg-success'
            name = 'Including'
            break
        case 1:
            className = 'text-danger bg-danger'
            name = 'Excluding'
            break
        default:
            name = [
                {
                    id: 'including',
                    value: 0,
                    label: 'Including',
                },
                {
                    id: 'excluding',
                    value: 1,
                    label: 'Excluding',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function actionType(name) {
    if (name.includes('add')) {
        return 'create'
    } else if (name.includes('change')) {
        return 'edit'
    } else if (name.includes('view')) {
        return 'view'
    } else {
        return 'status'
    }
}

export function title(name) {
    if (name.includes('add')) {
        return 'Add New'
    } else if (name.includes('change')) {
        return 'Edit'
    } else if (name.includes('view')) {
        return 'List'
    } else {
        return 'Status'
    }
}

// export function moduleName(name) {
//     return moduleList.find(item => item.key === name)?.name ?? name
// }

export function convertString(string) {
    if (typeof string === 'string') {
        let arr = string
            .toLowerCase()
            .split('-')
            .map(item => item.charAt(0).toUpperCase() + item.substring(1))
        return arr.join(' ')
    }
}
