import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, DatePicker, FileUpload, Select, TextInput } from 'components/Form'
import { createApiRecord, updateApiRecord, createLableApiRecord } from 'services/SerialService'
import { useDispatch, useSelector } from 'react-redux'
import { getRecord } from './store/dataSlice'
import reducer from './store'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { ProductFromOptions, yesNoOptionsNum } from 'utils/options'
import dayjs from 'dayjs'
import { Modal } from '../../../components/Ui'
import { attachInvoiceApi, getInvoiceDocApi } from '../../../services/ProductService'
import Tooltip from '../../../components/Ui/Tooltip'
injectReducer('serial', reducer)

const validationSchema = Yup.object().shape({
    product_serial_number: Yup.string().required('product serial number is required'),
    has_customer: Yup.number().required('this field is required'),
    user_email: Yup.string().when('has_customer', {
        is: has_customer => has_customer?.toString() === '1',
        then: schema =>
            schema
                .required('email is required')
                .email('Email is invalid')
                .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Email is invalid'),
        otherwise: schema => schema.nullable().optional(),
    }),
    warranty_period: Yup.number().when('has_customer', {
        is: has_customer => has_customer?.toString() === '1',
        then: schema => schema.required('warranty period is required'),
        otherwise: schema => schema.nullable().optional(),
    }),
    product_label: Yup.string().when('has_customer', {
        is: has_customer => has_customer?.toString() === '1',
        then: schema => schema.required('product name is required'),
        otherwise: schema => schema.nullable().optional(),
    }),
    invoice_number: Yup.string().when('has_customer', {
        is: has_customer => has_customer?.toString() === '1',
        then: schema => schema.required('invoice number is required'),
        otherwise: schema => schema.nullable().optional(),
    }),
    product_from: Yup.string().when('has_customer', {
        is: has_customer => has_customer?.toString() === '1',
        then: schema => schema.required('please select product from'),
        otherwise: schema => schema.nullable().optional(),
    }),
    manufacturer_name: Yup.string().nullable().optional(),
    seller_name: Yup.string().nullable().optional(),
    batch_number: Yup.string().nullable().optional(),
    sales_representative: Yup.string().nullable().optional(),
    purchase_date: Yup.date().when('has_customer', {
        is: has_customer => has_customer?.toString() === '1',
        then: schema => schema.required('purchase date is required'),
        otherwise: schema => schema.nullable().optional(),
    }),
})

const { REACT_APP_URL } = process.env

const Create = props => {
    const { initialData } = props
    const { id, productId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { record, productRecord, loading } = useSelector(state => state.serial.data)

    const [openAttachInvoiceModal, setOpenAttachInvoiceModal] = useState(false)
    const [openVerifyInvoiceModal, setOpenVerifyInvoiceModal] = useState(false)
    const [userProductId, setUserProductId] = useState(0)
    const [invoiceDoc, setInvoiceDoc] = useState([])

    // const [ setLoading] = useState(false)

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = id => dispatch(getRecord(id))

    const handleDiscard = () => navigate(`/manage-product/products/serials/${productId}`)

    const handleSubmit = datas => {
        const data = { ...datas, product_id: productId, is_active: true }
        if (id) {
            if (!data.customer_type) {
                delete data.customer_type
            }
            updateApiRecord(id, data)
                .then(() => {
                    Success('Updated Successfully!')
                    handleDiscard()
                })
                .catch(error => console.log('Error: ', error))
        } else {
            if (data?.has_customer?.toString() === '1') {
                createApiRecord(data)
                    .then(() => {
                        Success('Product Serial Created Successfully!')
                        handleDiscard()
                    })
                    .catch(error => console.log('Error: ', error))
            } else {
                const value = {
                    product_serial_number: data.product_serial_number,
                    has_customer: data.has_customer,
                    is_active: true,
                }
                createLableApiRecord(value)
                    .then(() => {
                        Success('Product Label Created Successfully!')
                        handleDiscard()
                    })
                    .catch(error => console.log('Error: ', error))
            }
        }
    }

    const handleUpload = (data, setSubmitting) => {
        setSubmitting(true)
        attachInvoiceApi(data)
            .then(() => {
                Success('Upload Successfully!')
                setOpenAttachInvoiceModal(false)
                setSubmitting(false)
            })
            .catch(error => console.log('Error: ', error))
    }

    // const handleVerify = data => {
    //     verifyInvoiceApi(data)
    //         .then(() => {
    //             Success(data.product_verified ? 'Verified Successfully!' : 'Rejected Successfully!')
    //             fetchData()
    //             setOpenVerifyInvoiceModal(false)
    //             setInvoiceDoc([])
    //             // setLoading(false)
    //         })
    //         .catch(error => console.log('Error: ', error))
    // }

    if (loading && id) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Serials</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">{id ? 'Update Serial' : 'Add New Serial'}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={id ? { ...record } : { ...initialData, ...productRecord }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                handleSubmit(values)
                            }}
                        >
                            {({ values, touched, errors }) => (
                                <Form>
                                    <div className="grid grid-cols-1 mb-4 gap-x-4 gap-y-4 md:grid-cols-4">
                                        <Field name="has_customer">
                                            {({ field, form }) => (
                                                <Select
                                                    options={yesNoOptionsNum}
                                                    label="Has Customer"
                                                    disabled={!!id}
                                                    size="sm"
                                                    className="md:col-span-2"
                                                    name="has_customer"
                                                    error={errors.has_customer && touched.has_customer}
                                                    message={errors.has_customer}
                                                    value={values?.has_customer}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="product_serial_number">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Serial Number"
                                                    name="product_serial_number"
                                                    error={errors.product_serial_number && touched.product_serial_number}
                                                    message={errors.product_serial_number}
                                                    value={values?.product_serial_number}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    {values?.has_customer?.toString() === '1' ? (
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                            <Field name="user_email">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Email"
                                                        name="user_email"
                                                        error={errors.user_email && touched.user_email}
                                                        message={errors.user_email}
                                                        value={values?.user_email}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="product_label">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Product Name"
                                                        name="product_label"
                                                        error={errors.product_label && touched.product_label}
                                                        message={errors.product_label}
                                                        value={values?.product_label}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="warranty_period">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Warranty Period (in months)"
                                                        name="warranty_period"
                                                        error={errors.warranty_period && touched.warranty_period}
                                                        message={errors.warranty_period}
                                                        value={values?.warranty_period}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="product_from">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={ProductFromOptions}
                                                        label="Product From"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="product_from"
                                                        error={errors.product_from && touched.product_from}
                                                        message={errors.product_from}
                                                        value={values?.product_from}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="purchase_date">
                                                {({ field, form }) => (
                                                    <DatePicker
                                                        className="md:col-span-2"
                                                        label="Purchase Date"
                                                        name="purchase_date"
                                                        error={errors.purchase_date && touched.purchase_date}
                                                        message={errors.purchase_date}
                                                        value={dayjs(values?.purchase_date).format('YYYY-MM-DD')}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                        max={dayjs(new Date()).format('YYYY-MM-DD')}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="invoice_number">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Invoice Number"
                                                        name="invoice_number"
                                                        error={errors.invoice_number && touched.invoice_number}
                                                        message={errors.invoice_number}
                                                        value={values?.invoice_number}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="manufacturer_name">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Manufacturer Name"
                                                        name="manufacturer_name"
                                                        error={errors.manufacturer_name && touched.manufacturer_name}
                                                        message={errors.manufacturer_name}
                                                        value={values?.manufacturer_name}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="batch_number">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Batch Number"
                                                        name="batch_number"
                                                        error={errors.batch_number && touched.batch_number}
                                                        message={errors.batch_number}
                                                        value={values?.batch_number}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="sales_representative">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Sales Representative"
                                                        name="sales_representative"
                                                        error={errors.sales_representative && touched.sales_representative}
                                                        message={errors.sales_representative}
                                                        value={values?.sales_representative}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="seller_name">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Seller Name"
                                                        name="seller_name"
                                                        error={errors.seller_name && touched.seller_name}
                                                        message={errors.seller_name}
                                                        value={values?.seller_name}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    ) : null}
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        {/*<Button*/}
                                        {/*    type={'button'}*/}
                                        {/*    className={`cursor-pointer hover:text-primary`}*/}
                                        {/*    onClick={() => {*/}
                                        {/*        setOpenAttachInvoiceModal(true)*/}
                                        {/*        setUserProductId(id)*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    Attach Invoice*/}
                                        {/*</Button>*/}

                                        <Button
                                            className={`cursor-pointer hover:text-primary`}
                                            type={'button'}
                                            onClick={() => {
                                                setOpenVerifyInvoiceModal(true)
                                                setUserProductId(id)
                                                getInvoiceDocApi({ user_product_id: id })
                                                    .then(response => setInvoiceDoc(response.data))
                                                    .catch(error => console.log('Error: ', error))
                                            }}
                                        >
                                            View Invoice
                                        </Button>

                                        <Button
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>

            <Modal
                show={openAttachInvoiceModal}
                onClose={() => {
                    setOpenAttachInvoiceModal(false)
                    setInvoiceDoc([])
                }}
            >
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">Attach Invoice</h2>
                    <Formik
                        initialValues={{
                            image: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleUpload({ ...values, user_product_id: userProductId }, setSubmitting)
                        }}
                    >
                        {({ values, touched, errors, isSubmitting }) => (
                            <Form className="flex flex-col gap-3">
                                <Field name="image">
                                    {({ field, form }) => (
                                        <FileUpload
                                            label="Image"
                                            className="md:col-span-2"
                                            name="image"
                                            error={errors.image && touched.image}
                                            message={errors.image}
                                            onChange={e => {
                                                form.setFieldValue(field.name, e.target.files[0])
                                            }}
                                        />
                                    )}
                                </Field>
                                <Button
                                    className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? 'Uploading...' : 'Upload'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>

            <Modal
                show={openVerifyInvoiceModal}
                onClose={() => {
                    setOpenVerifyInvoiceModal(false)
                    setInvoiceDoc([])
                }}
            >
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">View Invoice</h2>
                    {invoiceDoc[0]?.image ? (
                        <div className="flex justify-center w-full">
                            <div
                                className={`flex items-center justify-center mb-4 overflow-hidden ${invoiceDoc[0]?.image?.includes('.pdf') ? 'text-purple font-bold h-30' : 'w-40 h-40'}`}
                            >
                                <a href={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} target="_blank" rel="noreferrer">
                                    {invoiceDoc[0]?.image?.includes('.pdf') ? (
                                        <Tooltip title={'View'}>
                                            {invoiceDoc[0]?.image?.split('/')[invoiceDoc[0]?.image?.split('/').length - 1]}
                                        </Tooltip>
                                    ) : (
                                        <img src={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} alt="error" />
                                    )}
                                </a>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex gap-3">
                        {/*<Button*/}
                        {/*    className="w-full mt-3 text-white border-[#C67C4E] bg-[#C67C4E] dark:border-[#C67C4E] dark:bg-[#C67C4E]"*/}
                        {/*    disabled={loading}*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => {*/}
                        {/*        setLoading(true)*/}
                        {/*        handleVerify({*/}
                        {/*            product_verified: false,*/}
                        {/*            user_product_id: userProductId,*/}
                        {/*            warranty_verified: false,*/}
                        {/*        })*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {loading ? 'loading...' : 'Recapture'}*/}
                        {/*</Button>*/}
                        {/*<Button*/}
                        {/*    className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"*/}
                        {/*    disabled={loading}*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => {*/}
                        {/*        // setLoading(true)*/}
                        {/*        handleVerify({*/}
                        {/*            product_verified: true,*/}
                        {/*            user_product_id: userProductId,*/}
                        {/*            warranty_verified: true,*/}
                        {/*        })*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {loading ? 'Verifying...' : 'Verify'}*/}
                        {/*</Button>*/}
                    </div>
                </div>
            </Modal>
        </>
    )
}

Create.defaultProps = {
    initialData: {
        user_email: '',
        product_label: '',
        invoice_number: '',
        product_serial_number: '',
        warranty_period: '',
        product_from: '',
        manufacturer_name: '',
        seller_name: '',
        batch_number: '',
        sales_representative: '',
        purchase_date: '',
        has_customer: 0,
        is_active: true,
    },
}

export default Create
