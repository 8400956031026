import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Table } from 'components/Ui'
import { deleteApiRecord } from 'services/UserService'
import { Success } from 'utils/alerts'
import useAuth from 'utils/hooks/useAuth'
injectReducer('user', reducer)

export default function User() {
    const dispatch = useDispatch()
    const { checkPermission } = useAuth()
    const user = useSelector(state => state.auth.user)
    const { data, tableData } = useSelector(state => state.user.data)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        tableData.sort,
        tableData.query,
        tableData.pageIndex,
        tableData.pageSize,
        tableData.selectedTab,
        tableData.company,
    ])

    const fetchData = () => dispatch(getData(user?.company ? { ...tableData, company: user?.company?.id } : { ...tableData }))

    const handleDelete = id => {
        deleteApiRecord(id)
            .then(() => {
                Success('Deleted Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'company',
                header: 'Company',
                className: 'min-w-[150px]',
                // renderCell: row => row?.details?.companies[0]?.name,
            },
            {
                field: 'name',
                header: 'Name',
                className: 'min-w-[150px]',
                renderCell: row => `${row?.user?.first_name} ${row?.user?.last_name}`,
            },
            {
                field: 'email',
                header: 'Email',
                className: 'min-w-[150px]',
                renderCell: row => row?.user?.email,
            },
            {
                field: 'mobile',
                header: 'Contact No.',
                className: 'min-w-[150px]',
                renderCell: row => row?.user?.mobile,
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            {checkPermission('user', 'edit') && (user.super_user || user?.id === row?.user?.id) ? (
                                <Tooltip title={'Edit'}>
                                    <Link to={`/user/${row?.user?.id}/edit`} className="hover:text-primary">
                                        <HiOutlinePencil size={20} />
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {checkPermission('user', 'delete') ? (
                                <Tooltip title={'Delete'}>
                                    <ConfirmationModal
                                        message={'Are you sure you want to delete this record!'}
                                        onConfirm={() => {
                                            handleDelete(row?.user?.id)
                                        }}
                                    >
                                        <span className="hover:text-danger">
                                            <HiOutlineTrash size={20} />
                                        </span>
                                    </ConfirmationModal>
                                </Tooltip>
                            ) : null}
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.company]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Users</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={false}
                    companyFilter={user.super_user}
                    datas={data}
                    columns={columns}
                    add_new={checkPermission('user', 'create') ? '/user/create' : ''}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
