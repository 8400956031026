import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiData as getCompanyDataApi } from 'services/CompanyService'
import { getApiData as getCategoryDataApi } from 'services/CategoryService'
import { getApiData as getProductDataApi } from 'services/ProductService'

export const getCompanyData = createAsyncThunk('listdata/getCompanyData', async () => {
    const response = await getCompanyDataApi({ is_temporary: true, selectedTab: 'active' })
    return response.data.results?.map(item => {
        item.label = item.name
        item.value = item.id
        return item
    })
})

export const getCategoryData = createAsyncThunk('listdata/getCategoryData', async () => {
    const response = await getCategoryDataApi()
    return response.data.results?.map(item => {
        item.label = item.name
        item.value = item.id
        return item
    })
})

export const getProductData = createAsyncThunk('listdata/getProductData', async data => {
    const response = await getProductDataApi(data)
    return response.data?.results
        ?.map(item => {
            item.label = item.name
            item.value = item.id
            return item
        })
        .filter(item => item.is_component)
})

export const getProductDataRaw = createAsyncThunk('listdata/getProductDataRaw', async data => {
    const response = await getProductDataApi(data)
    return response.data?.results
        ?.map(item => {
            item.label = item.name
            item.value = item.id
            return item
        })
        .filter(item => !item.is_temporary)
})

const dataSlice = createSlice({
    name: 'listdata',
    initialState: {
        companyData: [],
        productData: [],
        categoryData: [],
        componentData: [],
    },
    reducers: {
        setCompanyData: (state, { payload }) => {
            state.companyData = payload
        },
        setProductData: (state, { payload }) => {
            state.productData = payload
        },
        setCategoryData: (state, { payload }) => {
            state.categoryData = payload
        },
        setComponentData: (state, { payload }) => {
            state.componentData = payload
        },
    },
    extraReducers: builder => {
        builder
            //company
            .addCase(getCompanyData.pending, state => {
                state.loading = true
            })
            .addCase(getCompanyData.fulfilled, (state, action) => {
                state.loading = false
                state.companyData = action.payload
            })
            .addCase(getCompanyData.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

            //category
            .addCase(getCategoryData.pending, state => {
                state.loading = true
            })
            .addCase(getCategoryData.fulfilled, (state, action) => {
                state.loading = false
                state.categoryData = action.payload
            })
            .addCase(getCategoryData.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

            //component
            .addCase(getProductData.pending, state => {
                state.loading = true
            })
            .addCase(getProductData.fulfilled, (state, action) => {
                state.loading = false
                state.componentData = action.payload
            })
            .addCase(getProductData.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

            //product
            .addCase(getProductDataRaw.pending, state => {
                state.loading = true
            })
            .addCase(getProductDataRaw.fulfilled, (state, action) => {
                state.loading = false
                state.productData = action.payload
            })
            .addCase(getProductDataRaw.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    },
})

export const { setCompanyData, setCategoryData } = dataSlice.actions

export default dataSlice.reducer
