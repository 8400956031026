import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Table } from 'components/Ui'
import { updateApiRecord, deleteApiRecord } from 'services/CompanyService'
import { Success } from 'utils/alerts'
import useAuth from 'utils/hooks/useAuth'
import { status } from 'components/helper'
injectReducer('company', reducer)

export default function Company() {
    const dispatch = useDispatch()
    const { checkPermission } = useAuth()
    const user = useSelector(state => state.auth.user)
    const { data, tableData } = useSelector(state => state.company.data)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        tableData.sort,
        tableData.query,
        tableData.pageIndex,
        tableData.pageSize,
        tableData.selectedTab,
        tableData.checkBox,
    ])

    const fetchData = () => dispatch(getData({ ...tableData, id: user.super_user ? '' : user?.company?.id }))

    const handleDelete = id => {
        deleteApiRecord(id)
            .then(() => {
                Success('Deleted Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const handleStatus = (id, data) => {
        delete data.image
        updateApiRecord(id, data)
            .then(() => {
                Success('Status Updated Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'name',
                header: 'Name',
                className: 'min-w-[150px]',
                orderable: true,
                // renderCell: row => <NameColumn row={row} />,
            },
            {
                field: 'company_email',
                header: 'Email',
                className: 'min-w-[150px]',
            },
            {
                field: 'phone',
                header: 'Contact No.',
                className: 'min-w-[150px]',
            },
            {
                field: 'city',
                header: 'city',
                className: 'min-w-[150px]',
                orderable: true,
            },
            {
                field: 'address',
                header: 'Address',
                className: 'min-w-[150px]',
            },
            {
                field: 'pin',
                header: 'Pin',
                className: 'min-w-[150px]',
            },
            {
                header: 'Status',
                renderCell: row => {
                    return checkPermission('company', 'status') ? (
                        <ConfirmationModal
                            onConfirm={() => {
                                handleStatus(row.id, { ...row, is_active: !row.is_active })
                            }}
                        >
                            {status(row.is_active)}
                        </ConfirmationModal>
                    ) : (
                        status(row.is_active)
                    )
                },
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            {checkPermission('company', 'edit') ? (
                                <Tooltip title={'Edit'}>
                                    <Link to={`/company/${row.id}/edit`} className="hover:text-primary">
                                        <HiOutlinePencil size={20} />
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {checkPermission('company', 'delete') ? (
                                <Tooltip title={'Delete'}>
                                    <ConfirmationModal
                                        message={'Are you sure you want to delete this record!'}
                                        onConfirm={() => {
                                            handleDelete(row.id)
                                        }}
                                    >
                                        <span className="hover:text-danger">
                                            <HiOutlineTrash size={20} />
                                        </span>
                                    </ConfirmationModal>
                                </Tooltip>
                            ) : null}
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.checkBox]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Companies</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={user.super_user}
                    checkboxFilter={user.super_user}
                    search_show={user.super_user}
                    datas={data}
                    columns={columns}
                    add_new={checkPermission('company', 'create') ? '/company/create' : ''}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
