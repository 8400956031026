import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Select, TextInput } from 'components/Form'
import { createApiRecord, updateApiRecord } from 'services/UserService'
import { useDispatch, useSelector } from 'react-redux'
import { getRecord } from './store/dataSlice'
import reducer from './store'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
injectReducer('user', reducer)

const validationSchema = Yup.object().shape({
    company: Yup.string().required('Please Select Company'),
    first_name: Yup.string().required('first name is required'),
    last_name: Yup.string().required('last name is required'),
    mobile: Yup.number()
        .required('Contact number is required')
        .max(999999999999, 'Contact number must be between 7 to 12 digits')
        .min(9999999, 'Contact number must be between 7 to 12 digits'),
    email: Yup.string()
        .required('email is required')
        .email('Email is invalid')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Email is invalid'),
})

const Create = props => {
    const { initialData } = props
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.auth.user)
    const { companyData } = useSelector(state => state.listData)
    const { record, loading, tableData } = useSelector(state => state.user.data)

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = id => dispatch(getRecord(id))

    const handleDiscard = () => navigate('/user')

    const handleSubmit = data => {
        if (id) {
            if (!data?.country_code) {
                delete data.country_code
            }
            if (!data?.registered_by) {
                delete data.registered_by
            }
            updateApiRecord(id, data)
                .then(() => {
                    Success('Updated Successfully!')
                    handleDiscard()
                })
                .catch(error => console.log('Error: ', error))
        } else {
            createApiRecord(data)
                .then(() => {
                    Success('Created Successfully!')
                    handleDiscard()
                })
                .catch(error => console.log('Error: ', error))
        }
    }

    if (loading && id) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Users</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">{id ? 'Update User' : 'Add New User'}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={
                                id
                                    ? { ...record }
                                    : { ...initialData, company: tableData?.company ? tableData?.company : companyData[0]?.id }
                            }
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                handleSubmit(values)
                            }}
                        >
                            {({ values, touched, errors }) => (
                                <Form>
                                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                        {user.super_user ? (
                                            <Field name="company">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={companyData}
                                                        label="Company"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        disabled={!!id}
                                                        name="company"
                                                        error={errors.company && touched.company}
                                                        message={errors.company}
                                                        value={values?.company}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                        ) : null}
                                        <Field name="first_name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="First Name"
                                                    name="first_name"
                                                    error={errors.first_name && touched.first_name}
                                                    message={errors.first_name}
                                                    value={values?.first_name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="last_name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Last Name"
                                                    name="last_name"
                                                    error={errors.last_name && touched.last_name}
                                                    message={errors.last_name}
                                                    value={values?.last_name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="email">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Email"
                                                    name="email"
                                                    error={errors.email && touched.email}
                                                    message={errors.email}
                                                    value={values?.email}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="mobile">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Contact Number"
                                                    name="mobile"
                                                    error={errors.mobile && touched.mobile}
                                                    message={errors.mobile}
                                                    value={values?.mobile}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        <Button
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

Create.defaultProps = {
    initialData: {
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        company: '',
    },
}

export default Create
