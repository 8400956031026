import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, userLoggedOut } from 'store/auth/userSlice'
import { apiLogIn, apiLogOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useNavigate } from 'react-router-dom'
import { Success } from 'utils/alerts'
import data from '../../assets/data/PermissionList.json'
import { getApiRecord } from 'services/UserService'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { token, refreshToken, signedIn } = useSelector(state => state.auth.session)
    const user = useSelector(state => state.auth.user)

    const signIn = async values => {
        try {
            const resp = await apiLogIn(values)
            if (resp.data) {
                const { auth_token, refresh_token } = resp.data?.data
                dispatch(onSignInSuccess({ token: auth_token, refreshToken: refresh_token }))
                let userDetails = ''
                if (!resp.data?.data?.super_user) {
                    userDetails = await getApiRecord(resp.data?.data?.user_id)
                }
                if (resp.data) {
                    resp.data.user = {
                        id: resp.data?.data?.user_id,
                        email: resp.data?.data?.email,
                        first_name: resp.data?.data?.first_name,
                        last_name: resp.data?.data?.last_name,
                        role: resp.data?.data?.role,
                        mobile: resp.data?.data?.mobile,
                        super_user: resp.data?.data?.super_user,
                        permissions: resp.data?.data?.permissions,
                        company: userDetails.data?.details?.companies[0] ?? '',
                    }

                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                first_name: 'User',
                                last_name: '',
                                userName: 'user@123',
                                email: 'user@gmail.com',
                            }
                        )
                    )
                }
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.detail || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(userLoggedOut(initialState))
        navigate('/login')
    }

    const signOut = async () => {
        apiLogOut({ refresh_token: refreshToken })
            .then(() => {
                Success('Logout successfully!')
                handleSignOut()
            })
            .catch(error => console.log('Error: ', error))
    }

    const checkPermission = (module, action) => {
        if (user.super_user) return true
        let permissionsData = []
        let status = false
        if (user?.role) {
            data.map(item => {
                if (item.role === user?.role) {
                    permissionsData = item.permissions
                }
                return item
            })
        }

        permissionsData.map(item => {
            if (action) {
                if (module === item.module && action === item.action) {
                    status = true
                }
            } else {
                if (module === item.module) {
                    status = true
                }
            }
            return item
        })
        return status
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
        checkPermission,
    }
}

export default useAuth
