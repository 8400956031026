import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiDotsVertical, HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Dropdown, Table } from 'components/Ui'
import { deleteApiRecord } from 'services/ProductService'
import { Success } from 'utils/alerts'
import { getProductData } from 'store/data/dataSlice'
import useAuth from 'utils/hooks/useAuth'
injectReducer('product', reducer)

export default function Products() {
    const dispatch = useDispatch()
    const { checkPermission } = useAuth()
    const user = useSelector(state => state.auth.user)
    const { data, tableData } = useSelector(state => state.product.data)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        tableData.sort,
        tableData.query,
        tableData.pageIndex,
        tableData.pageSize,
        tableData.selectedTab,
        tableData.company,
        tableData.components,
        tableData.companyType,
    ])

    const fetchData = () => dispatch(getData(user?.company ? { ...tableData, company: user?.company?.id } : { ...tableData }))

    const handleDelete = id => {
        deleteApiRecord(id)
            .then(() => {
                Success('Deleted Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'company',
                header: 'Company',
                className: 'min-w-[150px]',
                renderCell: row => row?.details?.company?.name,
            },
            {
                field: 'name',
                header: 'Name',
                className: 'min-w-[150px]',
                orderable: true,
            },
            {
                field: 'category',
                header: 'Category',
                className: 'min-w-[150px]',
                renderCell: row => row?.details?.category?.name,
            },
            {
                field: 'image',
                header: 'Image',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <div className="flex items-center justify-center w-20 h-20 overflow-hidden">
                            <a href={row?.image} target="_blank" rel="noreferrer">
                                <img src={row?.image ?? '/images/ypp-logo.png'} alt="error" />
                            </a>
                        </div>
                    )
                },
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            {checkPermission('products', 'edit') ? (
                                <Tooltip title={'Edit'}>
                                    <Link to={`/manage-product/products/${row?.id}/edit`} className="hover:text-primary">
                                        <HiOutlinePencil size={20} />
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {checkPermission('products', 'delete') ? (
                                <Tooltip title={'Delete'}>
                                    <ConfirmationModal
                                        message={'Are you sure you want to delete this record!'}
                                        onConfirm={() => {
                                            handleDelete(row?.id)
                                        }}
                                    >
                                        <span className="hover:text-danger">
                                            <HiOutlineTrash size={20} />
                                        </span>
                                    </ConfirmationModal>
                                </Tooltip>
                            ) : null}
                            {row.is_component ? null : (
                                <Dropdown>
                                    <Dropdown.Trigger downShow={false}>
                                        <Tooltip title={'More'} placement={'top'}>
                                            <span className={`text-lg cursor-pointer hover:text-primary`}>
                                                <HiDotsVertical />
                                            </span>
                                        </Tooltip>
                                    </Dropdown.Trigger>

                                    <Dropdown.Content>
                                        <Dropdown.Link to={`/manage-product/products/journey/${row?.id}`}>Journey</Dropdown.Link>
                                        <Dropdown.Link
                                            to={`/manage-product/products/components/${row?.id}`}
                                            onClick={() => dispatch(getProductData({ company: row?.company, components: true }))}
                                        >
                                            Components
                                        </Dropdown.Link>
                                        <Dropdown.Link to={`/manage-product/products/serials/${row?.id}`}>Serials</Dropdown.Link>
                                        <Dropdown.Link to={`/manage-product/products/faqs/${row?.id}`}>FAQs</Dropdown.Link>
                                    </Dropdown.Content>
                                </Dropdown>
                            )}
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [
            tableData.sort,
            tableData.query,
            tableData.pageIndex,
            tableData.pageSize,
            tableData.selectedTab,
            tableData.company,
            tableData.components,
            tableData.companyType,
        ]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Products</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={false}
                    companyFilter={user.super_user}
                    componentFilter={true}
                    companyTypeFilter={user.super_user}
                    datas={data}
                    columns={columns}
                    add_new={checkPermission('products', 'create') ? '/manage-product/products/create' : ''}
                    className={'min-h-[180px]'}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
