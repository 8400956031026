import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, getProductRecord, setTableData } from './store/dataSlice'
import { HiDotsVertical, HiOutlinePencil } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { Dropdown, Modal, Table } from 'components/Ui'
import { Link, useParams } from 'react-router-dom'
import { Button, FileUpload } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import { attachInvoiceApi, getInvoiceDocApi, verifyInvoiceApi } from 'services/ProductService'
import { Success } from 'utils/alerts'
import * as Yup from 'yup'
injectReducer('serial', reducer)

const { REACT_APP_URL } = process.env

const validationSchema = Yup.object().shape({
    image: Yup.string().required('this field is required'),
})

export default function Products() {
    const dispatch = useDispatch()
    const { productId } = useParams()
    const { data, tableData } = useSelector(state => state.serial.data)
    const [openAttachInvoiceModal, setOpenAttachInvoiceModal] = useState(false)
    const [openVerifyInvoiceModal, setOpenVerifyInvoiceModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userProductId, setUserProductId] = useState(0)
    const [invoiceDoc, setInvoiceDoc] = useState([])

    useEffect(() => {
        fetchData()
        dispatch(getProductRecord(productId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => {
        dispatch(getData({ ...tableData, product_id: productId }))
    }

    const handleUpload = (data, setSubmitting) => {
        setSubmitting(true)
        attachInvoiceApi(data)
            .then(() => {
                Success('Upload Successfully!')
                setOpenAttachInvoiceModal(false)
                setSubmitting(false)
            })
            .catch(error => console.log('Error: ', error))
    }

    const handleVerify = data => {
        verifyInvoiceApi(data)
            .then(() => {
                Success(data.product_verified ? 'Verified Successfully!' : 'Rejected Successfully!')
                fetchData()
                setOpenVerifyInvoiceModal(false)
                setInvoiceDoc([])
                setLoading(false)
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'user',
                header: 'Customer',
                className: 'min-w-[150px]',
                renderCell: row => `${row?.user?.first_name} ${row?.user?.last_name}`,
            },
            {
                field: 'product_label',
                header: 'product',
                className: 'min-w-[150px]',
                renderCell: row => row?.product_label ?? row?.product?.name,
            },
            {
                field: 'batch_number',
                header: 'Batch Number',
                className: 'min-w-[150px]',
            },
            {
                field: 'product_verified',
                header: 'Product Verified',
                className: 'min-w-[150px]',
            },
            {
                field: 'warranty_verified',
                header: 'Warranty Verified',
                className: 'min-w-[150px]',
            },
            {
                field: 'purchase_date',
                header: 'Purchase Date',
                className: 'min-w-[150px]',
                renderCell: row =>
                    row.purchase_date
                        ? new Date(row.purchase_date).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                          })
                        : '',
            },
            {
                field: 'created_at',
                header: 'Created Date',
                className: 'min-w-[150px]',
                renderCell: row =>
                    row.created_at
                        ? new Date(row.created_at).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                          })
                        : '',
            },
            {
                field: 'image',
                header: 'Image',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <div className="flex items-center justify-center w-20 h-20 overflow-hidden">
                            <a href={row?.user_product_photos[0]?.image ?? row?.product?.image} target="_blank" rel="noreferrer">
                                <img
                                    src={
                                        row?.user_product_photos[0]?.image
                                            ? row?.user_product_photos[0]?.image
                                            : row?.product?.image ?? '/images/ypp-logo.png'
                                    }
                                    alt="error"
                                />
                            </a>
                        </div>
                    )
                },
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Edit'}>
                                <Link
                                    to={`/manage-product/products/serials/${productId}/${row?.id}/edit`}
                                    className="hover:text-primary"
                                >
                                    <HiOutlinePencil size={20} />
                                </Link>
                            </Tooltip>
                            <Dropdown>
                                <Dropdown.Trigger downShow={false}>
                                    <Tooltip title={'More'} placement={'top'}>
                                        <span className={`text-lg cursor-pointer hover:text-primary`}>
                                            <HiDotsVertical />
                                        </span>
                                    </Tooltip>
                                </Dropdown.Trigger>

                                <Dropdown.Content>
                                    <Dropdown.Link
                                        className={`cursor-pointer hover:text-primary`}
                                        onClick={() => {
                                            setOpenAttachInvoiceModal(true)
                                            setUserProductId(row.id)
                                        }}
                                    >
                                        Attach Invoice
                                    </Dropdown.Link>
                                    <Dropdown.Link
                                        className={`cursor-pointer hover:text-primary`}
                                        onClick={() => {
                                            setOpenVerifyInvoiceModal(true)
                                            setUserProductId(row.id)
                                            getInvoiceDocApi({ user_product_id: row.id })
                                                .then(response => setInvoiceDoc(response.data))
                                                .catch(error => console.log('Error: ', error))
                                        }}
                                    >
                                        Verify Invoice
                                    </Dropdown.Link>
                                </Dropdown.Content>
                            </Dropdown>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.company]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Serials</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    add_new={`/manage-product/products/serials/${productId}/create`}
                    className={'min-h-[180px]'}
                    filter={false}
                    search_show={false}
                    datas={data}
                    columns={columns}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
            <Modal
                show={openAttachInvoiceModal}
                onClose={() => {
                    setOpenAttachInvoiceModal(false)
                    setInvoiceDoc([])
                }}
            >
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">Attach Invoice</h2>
                    <Formik
                        initialValues={{
                            image: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleUpload({ ...values, user_product_id: userProductId, parse_invoice: false }, setSubmitting)
                        }}
                    >
                        {({ values, touched, errors, isSubmitting }) => (
                            <Form className="flex flex-col gap-3">
                                <Field name="image">
                                    {({ field, form }) => (
                                        <FileUpload
                                            label="Image"
                                            className="md:col-span-2"
                                            name="image"
                                            error={errors.image && touched.image}
                                            message={errors.image}
                                            onChange={e => {
                                                form.setFieldValue(field.name, e.target.files[0])
                                            }}
                                        />
                                    )}
                                </Field>
                                <Button
                                    className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? 'Uploading...' : 'Upload'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>

            <Modal
                show={openVerifyInvoiceModal}
                onClose={() => {
                    setOpenVerifyInvoiceModal(false)
                    setInvoiceDoc([])
                }}
            >
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">Verify Invoice</h2>
                    {invoiceDoc[0]?.image ? (
                        <div className="flex justify-center w-full">
                            <div
                                className={`flex items-center justify-center mb-4 overflow-hidden ${invoiceDoc[0]?.image?.includes('.pdf') ? 'text-purple font-bold h-30' : 'w-40 h-40'}`}
                            >
                                <a href={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} target="_blank" rel="noreferrer">
                                    {invoiceDoc[0]?.image?.includes('.pdf') ? (
                                        <Tooltip title={'View'}>
                                            {invoiceDoc[0]?.image?.split('/')[invoiceDoc[0]?.image?.split('/').length - 1]}
                                        </Tooltip>
                                    ) : (
                                        <img src={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} alt="error" />
                                    )}
                                </a>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex gap-3">
                        <Button
                            className="w-full mt-3 text-white border-[#C67C4E] bg-[#C67C4E] dark:border-[#C67C4E] dark:bg-[#C67C4E]"
                            disabled={loading}
                            type="button"
                            onClick={() => {
                                setLoading(true)
                                handleVerify({
                                    product_verified: false,
                                    user_product_id: userProductId,
                                    warranty_verified: false,
                                })
                            }}
                        >
                            {loading ? 'loading...' : 'Recapture'}
                        </Button>
                        <Button
                            className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            disabled={loading}
                            type="button"
                            onClick={() => {
                                setLoading(true)
                                handleVerify({
                                    product_verified: true,
                                    user_product_id: userProductId,
                                    warranty_verified: true,
                                })
                            }}
                        >
                            {loading ? 'Verifying...' : 'Verify'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
